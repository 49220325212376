import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Newsletter from '../components/Newsletter';
import ShapefileLinks from '../components/ShapefileLinks';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import AboutImages from '../components/AboutImages';
import {AiFillFacebook} from 'react-icons/ai';
import {GoCheck} from 'react-icons/go';
import Helmet from 'react-helmet';

function ThanksLanding () {
  const aboutImages = AboutImages ();
  const screenshot = useStaticQuery (
    graphql`
      query {
        
        bitcoin:file(relativePath: {eq: "newsletter/1G5.png"}) {
          childImageSharp {
            fixed(width: 250, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
        hotmail:file(relativePath: {eq: "newsletter/mail-hotmail-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
        yahoo:file(relativePath: {eq: "newsletter/yahoo-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
      }`
  );
  return (
    <Layout>
      <SEO
        keywords={[`About`, 'Diego Valle', 'Diego Valle-Jones']}
        title="You are already signed-up. Thank you!"
        description=""
      />
      <Helmet>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Helmet>
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">

        <GoCheck
          size={40}
          className="container justify-center"
          color="#90EE90"
        />
        <h1 className="text-center">
          You are already signed-up. Thank you!
        </h1>

        <ShapefileLinks />

      </main>
    </Layout>
  );
}

export default ThanksLanding;
